"use strict";

class Notify {
    static info(message) {
        console.info(message);
        this._notify("info", message);
    }

    static success(message) {
        console.log(message);
        this._notify("success", message);
    }

    static warning(message) {
        console.warn(message);
        this._notify("warning", message);
    }

    static error(message) {
        console.error(message);
        this._notify("error", message);
    }

    static _notify(type, message) {
        new Noty({
            type: type,
            text: message,
            timeout: 3000
        }).show();
    }
}